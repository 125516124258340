import styled from "@emotion/styled";
import { MOBILE_QUERY_MAX_WIDTH, XXS } from "constants/vp-sizes";
export const StyledMobileContentComponent = styled.div`
  height: calc(100% - 54px);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .image-part-wrapper {
    max-width: 70vw;
    margin-bottom: 30vh;

    img {
      width: 100%;
    }
  }

  @media (max-width: ${MOBILE_QUERY_MAX_WIDTH}) {
    .image-part-wrapper {
      max-width: 80vw;
    }
  }

  @media (max-width: ${XXS - 1}px) {
    margin-bottom: 30vh;

    .image-part-wrapper {
      display: none;
    }
  }
`;
