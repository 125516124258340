import { isDevelopment } from "services/environment-service";
const isStorybook = process.env.IS_STORYBOOK === "true";
const getStagingAssetPath = (src)=>{
    return "https://mondaystaging.com" + src;
};
const isLocalAssetPath = (src)=>{
    return (isDevelopment || isStorybook) && src && src.startsWith("/static/");
};
export const getAssetPath = (src)=>{
    return isLocalAssetPath(src) ? getStagingAssetPath(src) : src;
};
