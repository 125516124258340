import styled from "@emotion/styled";
import { MOBILE_QUERY_MAX_WIDTH } from "constants/vp-sizes";
export const StyledSecurityAndPrivacyComponent = styled.div`
  .image-gallery {
    padding: 24px;

    .social-proof-gallery-picture {
      img {
        height: 148px;
      }
    }
  }

  @media (max-width: ${MOBILE_QUERY_MAX_WIDTH}) {
    .images-gallery-row {
      .image-gallery {
        padding: 4px;

        .social-proof-gallery-picture {
          img {
            height: 50px;
          }
        }
      }
    }
  }
`;
