import styled from "@emotion/styled";
import { MOBILE_QUERY_MAX_WIDTH } from "/constants/vp-sizes";
export const StyledSimpleColorfulTitleWithSignupComponent = styled.div`
  text-align: center;

  .title-wrapper {
    .colorful-title {
      font-weight: 700;

      span {
        font-weight: 700;
      }
    }
  }

  .subtitle-wrapper {
    margin-top: 16px;

    h2 {
      font-size: 1.125rem;
    }
  }

  .signup-form-with-decorations-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 24px;
  }

  @media (max-width: ${MOBILE_QUERY_MAX_WIDTH}) {
    padding-left: 24px;
    padding-right: 24px;
  }
`;
