import styled from "@emotion/styled";
export const StyledVideosGalleryComponent = styled.div`
  width: 100vw;
  padding: 48px 0;

  .videos-gallery-title {
    margin-bottom: 32px;

    font-size: calc(1vw + 0.875rem);
    line-height: 48px;
    text-align: center;

    b {
      font-weight: 500;
    }
  }

  .videos-list-wrapper {
    max-width: 900px;
    margin: auto;

    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
`;
