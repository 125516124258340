import styled from "@emotion/styled";
import { TABLET_QUERY_MAX_WIDTH } from "constants/vp-sizes";
import { HIDDEN } from "constants/z-index";
export const StyledBackgroundTriangleComponent = styled.div`
  position: absolute;
  border-bottom: 100vh solid;
  border-left: 18vw solid transparent;
  width: 100%;
  top: 0;
  right: 0;

  &.straight-border {
    border-left: none;
  }

  @media (max-width: ${TABLET_QUERY_MAX_WIDTH}) {
    border-bottom: none;
    border-left: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    z-index: ${HIDDEN};
  }
`;
