import styled from "@emotion/styled";
export const StyledOnePagerWithImageComponent = styled.div`
  .one-pager-with-image-content-component {
    .desktop-content-wrapper {
      max-width: 1400px;

      .desktop-content-component {
        .text-part-wrapper {
          width: 45%;
          flex-shrink: 0;
        }

        .image-wrapper {
          flex-grow: 1;

          .image-part-wrapper {
            width: 90%;
            padding: 16px 16px;
          }
        }
      }
    }
  }
`;
