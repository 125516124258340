import styled from "@emotion/styled";
import { LAYER_ONE } from "constants/z-index";
import { TABLET_QUERY_MAX_WIDTH } from "constants/vp-sizes";
export const StyledOnePagerWithImageContentComponent = styled.div`
  width: 100vw;
  display: flex;
  justify-content: space-around;

  .desktop-content-wrapper {
    width: 100%;
  }

  .mobile-content-wrapper {
    display: none;
  }

  @media (max-width: ${TABLET_QUERY_MAX_WIDTH}) {
    .desktop-content-wrapper {
      display: none;
    }

    .mobile-content-wrapper {
      display: flex;
      z-index: ${LAYER_ONE};
      justify-content: center;
      align-items: center;
    }
  }
`;
