const convertToArrayIfNeeded = (obj)=>{
    return Array.isArray(obj) ? obj : [
        obj
    ];
};
// Finds an empty cell index in the given array
const findEmptyIndex = (arr)=>{
    if (arr) {
        for(let i = 0; i < arr.length; i++){
            if (!arr[i]) {
                return i;
            }
        }
        return arr.length;
    }
    return -1;
};
// returns a random value from the given array
const getRandomValue = (arr)=>{
    const randomIndex = Math.floor(Math.random() * arr.length);
    return arr[randomIndex];
};
// moves the given values to the beginning of the array
const moveToBeginningIfExists = (arr, valuesToBeAtBeginning)=>{
    if (arr && valuesToBeAtBeginning) {
        for(let i = valuesToBeAtBeginning.length - 1; i >= 0; i--){
            const value = valuesToBeAtBeginning[i];
            const index = arr.indexOf(value);
            if (index > -1) {
                arrayMove(arr, index, 0);
            }
        }
    }
    return arr;
};
const arrayMove = (arr, fromIndex, toIndex)=>{
    var element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
};
const gettArrayWithIncrementingNumbers = (size)=>{
    return Array.from(Array(size).keys());
};
module.exports = {
    convertToArrayIfNeeded,
    findEmptyIndex,
    getRandomValue,
    moveToBeginningIfExists,
    arrayMove,
    gettArrayWithIncrementingNumbers
};
