import styled from "@emotion/styled";
import { MOBILE_QUERY_MAX_WIDTH, TABLET_QUERY_MAX_WIDTH } from "constants/vp-sizes";
export const StyledCustomerSuccessComponent = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;

  .paragaph-and-asset-columns .top-line {
    box-sizing: content-box;
    flex-direction: column-reverse;
    justify-content: flex-end !important;

    .paragraph-component .title .colorful-title {
      font-size: 1.375rem;

      .colored {
        font-size: 1.375rem;
      }
    }

    .asset-column {
      margin-bottom: 32px;
    }

    .content-column,
    .asset-column {
      max-width: 100% !important;
    }

    .asset-inner {
      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  @media (max-width: ${TABLET_QUERY_MAX_WIDTH}) {
    .paragaph-and-asset-columns {
      width: 100%;
      height: 100%;

      text-align: center;

      .top-line {
        text-align: center;
        margin: 32px auto;
        width: 100% !important;
      }
    }
  }

  @media (max-width: ${MOBILE_QUERY_MAX_WIDTH}) {
    flex-direction: column;
    div.paragaph-and-asset-columns div.top-line {
      flex-direction: column-reverse !important;
    }
  }
`;
