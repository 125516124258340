import ParagraphComponent from "./paragraph/paragraph-component";
import SimpleColorfulTitleWithSignupComponent from "./simple-colorful-title-with-signup/simple-colorful-title-with-signup-component";
import CustomerSuccessComponent from "./customer-success/customer-success-component";
import SecurityAndPrivacyComponent from "./security-and-privacy/security-and-privacy-component";
import TextAndAssetComponent from "./text-and-asset/text-and-asset-component";
import OnePagerWithImageComponent from "./one-pager-with-image/one-pager-with-image-component";
import ShortFooterComponent from "./short-footer/short-footer-component";
import ReviewsComponent from "./reviews/reviews-component";
import CompaniesLogosComponent from "./companies-logos/companies-logos-component";
export default {
    ParagraphComponent,
    SimpleColorfulTitleWithSignupComponent,
    CustomerSuccessComponent,
    SecurityAndPrivacyComponent,
    TextAndAssetComponent,
    OnePagerWithImageComponent,
    ShortFooterComponent,
    ReviewsComponent,
    CompaniesLogosComponent
};
