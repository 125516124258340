import styled from "@emotion/styled";
import colors from "styles/colors";
export const StyledVideoThumbnailItemComponent = styled.div`
  height: 320px;
  width: 280px;

  display: flex;
  flex-direction: column;

  margin-bottom: 32px;

  border-radius: 4px;
  box-shadow: 0 2px 13px 0 rgba(206, 206, 206, 0.5);

  cursor: pointer;

  transition: box-shadow 0.3s;

  .thumbnail-image-part {
    position: relative;

    height: 200px;
    width: 100%;

    .thumbnail-image {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;

      background-size: cover;
      background-position: center;

      transition: filter 0.3s;
    }

    .play-icon-container {
      position: absolute;
      bottom: 12px;
      left: 16px;

      svg {
        transition: transform 0.3s;
      }
    }
  }

  .thumbnail-text-part {
    flex: 1;

    display: flex;
    flex-direction: column;
    justify-content: center;

    padding: 0 16px;

    .video-title {
      font-size: 1.375rem;
      line-height: 32px;

      margin-bottom: 8px;
    }

    .link-indicator {
      font-size: 0.875rem;
      line-height: 24px;

      text-decoration: underline;

      color: ${colors["brand-blue"]};

      &:hover {
        color: ${colors["light-blue-hover"]};
      }
    }
  }

  &:hover {
    box-shadow: 0 10px 40px 0 rgba(192, 192, 192, 0.4);

    .thumbnail-image-part {
      .thumbnail-image {
        filter: brightness(75%);
      }

      .play-icon-container {
        svg {
          transform: scale(1.1);
        }
      }
    }
  }
`;
