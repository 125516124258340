import styled from "@emotion/styled";
import { MOBILE_QUERY_MAX_WIDTH } from "/constants/vp-sizes";
export const StyledParagraphAndAssetComponent = styled.div`
  display: flex;
  flex-direction: column;

  .top-line {
    flex: 1 0 auto;
    display: flex;
    justify-content: space-between;

    .content-column {
      max-width: 45%;
      display: flex;
      align-items: center;
      justify-content: center;

      .signup-wrapper {
        margin-top: 16px;
      }
    }

    .asset-column {
      max-width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
    }
  }

  &.asset-left {
    .top-line {
      flex-direction: row-reverse;
    }
  }

  @media (max-width: ${MOBILE_QUERY_MAX_WIDTH}) {
    height: auto;

    .top-line {
      flex-direction: column;
      flex-wrap: wrap;
      align-items: center;

      .content-column {
        flex: 0 0 100%;
        margin-bottom: 48px;
        max-width: 100%;
      }

      .asset-column {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
  }
`;
export const StyledContent = styled.div`
  div {
    font-size: 0.875rem;
    font-weight: 300;
    line-height: 1.6;
    margin-bottom: 16px;
  }
`;
