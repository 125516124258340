import styled from "@emotion/styled";
import { LAYER_ONE } from "constants/z-index";
import { TABLET_QUERY_MAX_WIDTH } from "constants/vp-sizes";
export const StyledDesktopContentComponent = styled.div`
  height: calc(100vh - 65px);
  overflow: hidden;

  display: flex;
  align-items: center;

  .text-part-wrapper {
    width: 45vw;
    display: flex;
    align-items: center;
    padding: 16px 24px 16px 80px;
  }

  .image-wrapper {
    width: calc(100vw - 45vw);
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;

    .image-part-wrapper {
      width: 45vw;
      display: flex;
      position: relative;
      padding: 4vw 4vw;

      z-index: ${LAYER_ONE};
      align-items: center;
      justify-content: center;

      img {
        max-width: 100%;
        max-height: 80vh;
      }

      &.align-bottom {
        align-self: flex-end;
      }

      &.align-top {
        align-self: flex-start;
      }
    }
  }

  &.straight-border {
    .text-part-wrapper {
      width: 50vw;
    }

    .image-wrapper {
      width: calc(100vw - 50vw);

      .image-part-wrapper {
        width: 50vw;
      }
    }
  }

  @media (max-width: ${TABLET_QUERY_MAX_WIDTH}) {
    .text-part-wrapper {
      padding: 16px 0 16px 54px;
    }
  }
`;
